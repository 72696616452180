import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class TermsWebTc extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.nonce = localStorage.getItem('nonce') || 'default_nonce';
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
            script2.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            /*const script3 = document.createElement("script");
            script3.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);*/
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/tc"><img draggable="false" src={luluhk} alt="luluhk"/></a>

            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/tc/privacy">私隱</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/tc/terms">條款</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>條款和細則</h1>
                <p>版本：1.0 | 生效日期：2025年2月1日</p>
                <hr/>
                <p>路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附屬公司和/或關聯公司（統稱“路路香港”）透過luluhk.com、其子域、商家服務系統、任何其他全部或部分由路路香港開發的網站、其子域、媒體平台或應用程式包括路路香港流動應用程式（個別或統稱“渠道”）提供服務。</p>
                <p>用戶進入和使用渠道必須遵守本<strong>條款和細則</strong>及<strong>私隱政策</strong>。通過使用渠道和服務或創建路路香港賬戶，渠道的用戶（無論註冊與否）（簡稱“用戶”）確認他/她/它已詳細閱讀和理解<strong>條款和細則</strong>及<strong>私隱政策</strong>並同意受其約束。若用戶不接受任何<strong>條款和細則</strong>及<strong>私隱政策</strong>，請不要加入、訪問或使用任何由我們通過渠道提供的服務。</p>
                <p>我們保留權利在沒有預先通知的情況下，當我們認為必要時可隨時更新、修訂、更改或修改<strong>條款和細則</strong>及<strong>私隱政策</strong>。我們強烈建議用戶定期查看最新的<strong>條款和細則</strong>及<strong>私隱政策</strong>。修訂後的<strong>條款和細則</strong>及<strong>私隱政策</strong>會在經修訂的版本發佈在相應網頁時立即生效。用戶一旦通知我們不接受任何改動，我們會立即終止其進入渠道和服務，否則用戶於上述改動後繼續使用路路香港的渠道和服務將被視為接受所有改動。</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>定義</h2>
                        <p>在本<strong>條款和細則</strong>中，除文意另有所指外：</p>
                        <dl>
                            <div>
                                <dt>協議</dt>
                                <dd>指本<strong>條款和細則</strong>、<strong>私隱政策</strong>和任何服務訂單或免費試用優惠的條款和細則；</dd>
                            </div>
                            <div>
                                <dt>授權使用者</dt>
                                <dd>指獲商家授權使用商家系統的人士；</dd>
                            </div>
                            <div>
                                <dt>渠道</dt>
                                <dd>個別或統指luluhk.com、其子域、商家服務系統、任何其他全部或部分由路路香港開發的網站、其子域、媒體平台或應用程式包括路路香港流動應用程式；</dd>
                            </div>
                            <div>
                                <dt>仲裁中心</dt>
                                <dd>指香港國際仲裁中心；</dd>
                            </div>
                            <div>
                                <dt>香港</dt>
                                <dd>指香港特別行政區；</dd>
                            </div>
                            <div>
                                <dt>路路香港</dt>
                                <dd>統指路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附屬公司和/或關聯公司；</dd>
                            </div>
                            <div>
                                <dt>材料</dt>
                                <dd>指在渠道上的內容、商家資料、商店資料、產品資料、照片、文本、徽標、圖像、圖形、連結和/或其他任何資訊和材料；</dd>
                            </div>
                            <div>                         
                                <dt>會員</dt>	
                                <dd>指在渠道上擁有註冊賬戶的個人或實體；</dd>
                            </div>
                            <div>                         
                                <dt>商家</dt>	
                                <dd>指任何使用商家服務的組織和其關聯人士；</dd>
                            </div>
                            <div>                         
                                <dt>商家服務</dt>	
                                <dd>指由我們提供給商家的商家系統和任何其他商家服務；</dd>
                            </div>
                            <div>                         
                                <dt>商家系統</dt>	
                                <dd>指由我們開發的商家服務系統；</dd>
                            </div>
                            <div>                         
                                <dt>參與分行/分店</dt>	
                                <dd>指由商家指定使用商家服務的分行或分店；</dd>
                            </div>
                            <div>                         
                                <dt>個人資料</dt>	
                                <dd>指個人資料和數據；</dd>
                            </div>
                            <div>                         
                                <dt>產品</dt>	
                                <dd>指產品和服務；</dd>
                            </div>
                            <div>                         
                                <dt>關聯人士</dt>	
                                <dd>指商家的參與分行/分店、僱員和任何其他授權使用者；</dd>
                            </div>
                            <div>                         
                                <dt>服務訂單</dt>	
                                <dd>指我們與一方之間訂立的任何合同、報價、登記表格或服務訂購表格以享有路路香港某些服務；</dd>
                            </div>
                            <div>                         
                                <dt>上傳者</dt>	
                                <dd>指在渠道上上傳或張貼任何材料的用戶；</dd>
                            </div>
                            <div>                         
                                <dt>用戶</dt>	
                                <dd>指使用渠道的個人或實體，包括非註冊用戶、註冊用戶和商家；及</dd>
                            </div>
                            <div>                         
                                <dt>瀏覽者</dt>	
                                <dd>指瀏覽和/或使用在渠道上材料的用戶。</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>一般條款</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">我們根據<strong>協議</strong>透過渠道提供下列一項或多項服務：（a）作為用戶瀏覽和分享商家的平台；（b）作為用戶瀏覽和分享有關商家參與分行/分店的平台；（c）作為用戶瀏覽和分享有關商家產品的平台；（d）為商家提供的商家系統；及（e）其他經渠道提供的各項功能、服務和/或應用程式；</li>
                            <li className="static-web-styling ol-li2-li">我們保留可自行決定隨時以任何理由更改任何服務而不作通知之權利，包括但不限於添加、更改或刪除渠道的任何部分，以及更改或刪除在渠道上的任何材料；</li>
                            <li className="static-web-styling ol-li2-li">我們可能會投資於第三方的營銷活動，包括但不限於Google Ads、Bing Ads、Facebook Ads、Instagram Ads和其他廣告網絡，以進一步宣傳商家。 用戶接受並同意此類促銷可能不在路路香港的網域中；</li>
                            <li className="static-web-styling ol-li2-li">除非另有明確註明，路路香港不會參與任何用戶之間的實際交易；</li>
                            <li className="static-web-styling ol-li2-li">我們可自行決定當我們認為有必要時隨時為渠道實施預先安排及非預先安排的維護和數據備份；</li>
                            <li className="static-web-styling ol-li2-li">在用戶沒有簽署任何服務訂單而獲得渠道任何功能的免費試用下，試用服務期限將由我們全權決定。此類用戶進入和使用渠道必須遵守本<strong>條款和細則</strong>、<strong>私隱政策</strong>及免費試用優惠的條款（如有）；及</li>
                            <li className="static-web-styling ol-li2-li">有關我們如何使用和保護用戶通過渠道上提供的個人資料，請參閱<strong>私隱政策</strong>。</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>會員註冊</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">用戶可以無須註冊成為會員在渠道上自由瀏覽供所有用戶使用的材料。然而，某些渠道的材料與功能只提供給會員使用；</li>
                            <li className="static-web-styling ol-li2-li">用戶保證在註冊成為會員時所提供的資料都是真實、準確、完整、合法，以及在各方面都沒有誤導成份，並且如該資料日後有任何變更，他們理應及時更新資料；</li>
                            <li className="static-web-styling ol-li2-li">用戶不可選擇一個會侵犯任何一方商標或其他專有權利，或者我們認為帶有攻擊性或不適當的用戶名稱。我們保留以通知要求任何用戶更改其用戶名稱的權利。若用戶拒絕遵守此要求，我們有權在沒有預先通知該用戶的情況下暫停或終止該用戶賬戶。在任何情況下，我們將不會就任何用戶不正當使用用戶名稱而承擔任何責任；</li>
                            <li className="static-web-styling ol-li2-li">會員須獨自地和全面地負責保密其用戶名稱和密碼及其賬戶的任何其他資料。會員應立即通知我們如發現任何未經授權而進入或使用其賬戶。我們不會就因未經授權使用賬戶而造成任何損失或損害承擔責任；</li>
                            <li className="static-web-styling ol-li2-li">我們有全權及絕對酌情權拒絕任何用戶註冊成為會員的申請。而用戶同意我們沒有義務向用戶發送任何拒絕會員註冊申請的通知或解釋；及</li>
                            <li className="static-web-styling ol-li2-li">我們保留當我們認為有必要時隨時暫停或終止任何用戶賬戶的權利（詳情請參閱第15條“<strong>賬戶終止和材料移除</strong>”）。</li>
                        </ol><br/> 
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>受禁止的用途</h2>
                        <p>所有渠道的用戶同意：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">用戶如果不具有法律能力構成具有法律約束力的合同或被法律禁止進入或使用我們的服務，不應使用渠道；</li>
                            <li className="static-web-styling ol-li2-li">用戶不得使用渠道以任何方式侵犯任何一方的知識產權或其他專有權利；</li>
                            <li className="static-web-styling ol-li2-li">用戶不得上傳、張貼、傳輸、分享或儲存任何材料，以任何方式（a）違反任何適用法律及規例；（b）違反或侵犯他人版權、商標、商業秘密、專利、私隱、公開信息、知識產權或其他專有權利；（c）非法的、不準確的、有害的、具恐嚇性的、帶辱駡性的、騷擾的、侵權的、具誹謗性的、粗俗的、淫褻的、色情的、帶誤導性的、具憎恨性的、或在種族上、民族上或其他方面有攻擊性或令人反感的；（d）用於除依據<strong>協議</strong>以外的其他商業目的；或（e）包含病毒、木馬，或者可能中斷、破壞或限制渠道和服務或任何電腦軟、硬體或電信設備的任何素材；</li>
                            <li className="static-web-styling ol-li2-li">用戶不得刪除或修改其他用戶發佈的任何材料；</li>
                            <li className="static-web-styling ol-li2-li">用戶不得改變、複製、調整、分發、發佈、展示、編譯、進行代碼反向工程、反彙編、反編譯，或嘗試創造源自渠道的任何來源代碼；</li>
                            <li className="static-web-styling ol-li2-li">用戶禁止侵犯或企圖違反渠道上的安全性，包括但不限於訪問其無訪問權限的數據、登入未經授權的伺服器或賬戶、試圖探查、掃描或測試系統或網路的脆弱性、未經適當授權而試圖違反安全或認證措施、企圖幹擾任何用戶、主機或網絡的服務，或者發送未經認可的電子郵件、信息、帖子或以任何其他形式發送垃圾郵件。如違反系統或網路安全可能會引致民事和/或刑事的法律責任；及</li>
                            <li className="static-web-styling ol-li2-li">用戶在未經有關用戶的同意下不得存取、下載、複製或使用其個人資料。</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>可接受的用途</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li"><u>特定用途 — 上傳者</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">上傳者同意他/她/它僅用渠道於合法用途以及享受渠道所提供的服務；</li>
                                    <li className="static-web-styling ol-li3-li">上傳者須對其上傳或發佈在渠道上的材料負全部責任；</li>
                                    <li className="static-web-styling ol-li3-li">上傳者接受並同意，當在渠道上上傳或發佈材料，這些材料都會被公開，並可能由其他渠道用戶分享至第三方網站/媒體平台/應用程式；</li>
                                    <li className="static-web-styling ol-li3-li">上傳者接受並同意他們/她們/它們不會因發佈任何材料而獲得補償；</li>
                                    <li className="static-web-styling ol-li3-li">我們保留權利決定材料在渠道發佈的期限、材料的尺寸、位置和佈局；</li>
                                    <li className="static-web-styling ol-li3-li">我們保留權利當我們認為適當時隨時編輯、分享及刪除任何發佈在渠道上的材料；</li>
                                    <li className="static-web-styling ol-li3-li">我們有權自行決定暫停或終止向任何上傳者提供任何服務；及</li>
                                    <li className="static-web-styling ol-li3-li">當上傳者發佈材料後，如果該材料後來被他/她/它或我們刪除，或者其賬戶已被終止，該上傳者將再無法訪問這些材料。然而，被刪除的材料仍可能出現在渠道的任何部分和/或被我們以任何其他形式使用。</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>特定用途 — 瀏覽者</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">瀏覽者同意他/她/它僅用渠道於合法用途以及享受渠道所提供的服務；</li>
                                    <li className="static-web-styling ol-li3-li">瀏覽者同意通過渠道接收到的任何個人資料，只會用作鑒定或搜尋材料，或者使其享受渠道所提供服務。任何接收到的個人資料與上述目的無關的須被忽視，且不可以任何形式儲存、使用、處理、分享、披露或傳播。瀏覽者亦同意在上述目的已完成後，立即適當地刪除任何接收到的個人資料；及</li>
                                    <li className="static-web-styling ol-li3-li">若任何瀏覽者違反<strong>協議</strong>，無論在香港或其他地方，使用任何其他用戶的個人資料或材料作任何用途，我們將不承擔任何責任。所有用戶接受其公開在渠道上自願提供的所有個人資料或材料，由其自行承擔提供資料的所有風險。</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>特定用途 – 商家</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">商家同意它僅用渠道於合法用途以及享受渠道所提供的商家服務；</li>
                                    <li className="static-web-styling ol-li3-li">於向路路香港支付服務費或接受任何免費優惠後，商家將有權在遵守<strong>協議</strong>的條款下使用渠道來上傳或發佈有關其商店和產品的資料，和/或其他商業性素材；</li>
                                    <li className="static-web-styling ol-li3-li">商家可撤銷、非獨家、不可轉授、不可轉讓和全球性使用商家服務的權利在<strong>協議</strong>所規定的服務期內有效，直至期限屆滿或服務終止，以較早者為準。服務期將會在商家自動或手動支付了下個時期的服務費後更新；</li>
                                    <li className="static-web-styling ol-li3-li">我們保留權利當我們認為適當時隨時更改服務費或徵收新的費用；</li>
                                    <li className="static-web-styling ol-li3-li">我們可能為不同商家提供附加系統功能。任何附加功能的使用可能需要相關商家同意此附加功能特定的額外條款和細則，而此特定條款可能會被納入<strong>協議</strong>內；</li>
                                    <li className="static-web-styling ol-li3-li">商家保證並承諾（a）它享有法人地位的獨立實體，並根據其註冊所在司法管轄區的法律正式註冊成立並有效存在及擁有良好的信譽；（b）它和每個參與分行/分店在它們經營所在司法管轄區內已適當地註冊和持牌以提供發佈在渠道上的產品，以及遵守當地所有適用法例和法規；（c）它具有簽訂、執行和履行<strong>協議</strong>及下文規定的義務和交易的完全企業能力、權力和法定權利；（d）<strong>協議</strong>的簽訂和履行不會違反任何適用法律；和（e）在其認知下，沒有受到任何正在等候或知情的法律行動、起訴、程序、調查或訴訟的威脅，而任何個別或總體威脅可能對其履行<strong>協議</strong>義務的能力產生重大和不利的影響。如商家得知任何上述保證或承諾中有/將有任何錯誤，商家須及時通知我們；</li>
                                    <li className="static-web-styling ol-li3-li">商家須完全遵守香港法例第362章《商品說明條例》，和/或在參與分行/分店經營所在司法管轄區的其他適用法律；</li>
                                    <li className="static-web-styling ol-li3-li">商家保證發佈在渠道上的產品價格（每個子產品的價格、每個選項的價格和總價）和優惠是：<ul className="static-web-styling ul-li4"><li className="static-web-styling ul-li4-li">提供給渠道的特別價格/優惠；或</li><li className="static-web-styling ul-li4-li">在參與分行/分店列出的價格/優惠；或</li><li className="static-web-styling ul-li4-li">在其他線下/線上的官方/第三方渠道列出的價格/優惠；</li></ul>價格以最低者為準和優惠以對顧客最佳者為準；</li>
                                    <li className="static-web-styling ol-li3-li">商家確保經商家系統提供任何有關商家的材料，包括但不限於產品之價格和庫存數量或可得性、優惠、參與分行/分店之名稱、地址、狀態（正常營業、裝修中、暫時停業、永久停業或已搬遷）、營業時間和聯絡資料，及供我們內部溝通之商家聯絡人資料，屬準確、最新、完整、合法、有效和不侵犯任何第三方知識產權；</li>
                                    <li className="static-web-styling ol-li3-li">我們有權對任何上傳到商家系統上的材料，在其發佈到渠道上之前預先篩選。我們有權拒絕、編輯、刪除，或者要求刪除或修改任何我們自行判斷為不適當或違反<strong>協議</strong>的任何條款或任何適用法律或法規之材料；</li>
                                    <li className="static-web-styling ol-li3-li">我們有權隨時變更、收回或移除發佈在路路香港的識別碼/用戶名稱；</li>
                                    <li className="static-web-styling ol-li3-li">我們可能會披露或公佈任何由商家提供而沒有保密性質的材料用以推廣商家服務；</li>
                                    <li className="static-web-styling ol-li3-li">商家擁有並提供給路路香港的任何標誌、設計、圖形和/或其他相關素材中的知識產權歸有關商家所有；</li>
                                    <li className="static-web-styling ol-li3-li">商家須自行負責提供就使用商家服務所需的內部設施配置，包括但不限於流動裝置和電子通訊設施負責。我們不會對此等設施配置的任何故障或維護承擔任何責任；</li>
                                    <li className="static-web-styling ol-li3-li">商家須完全遵守香港法例第486章《個人資料（私隱）條例》的規定，和/或在參與分行/分店經營所在司法管轄區的其他適用法律，以適當地保護在任何情況下由我們提供或通過渠道收集的個人資料；</li>
                                    <li className="static-web-styling ol-li3-li">商家須就任何使用商家服務之關聯人士違反<strong>協議</strong>的任何條款或者任何適用法律或法規而負全責。如果發現商家違反任何條款或有任何不誠實的行為，包括但不限於提供不準確的價格和庫存數量信息，我們有權自行決定暫停或終止向有關商家提供任何或全部其訂閱的商家服務，及/或暫停或終止其進入和使用商家系統，而毋須因此給予其任何補償和通知；</li>
                                    <li className="static-web-styling ol-li3-li">我們有權自行決定隨時修改、升級或刪除商家系統的部分或全部元件而不必通知商家，商家無權拒絕上述改動。然而，如果我們對商家系統做出可能影響系統之正常運作的改動，我們將會合理地通知商家有關上述改動；</li>
                                    <li className="static-web-styling ol-li3-li">商家應當把商家系統之任何故障或缺陷及時告知我們，以及提供任何我們要求的數據或輸出資料用作故障排除；</li>
                                    <li className="static-web-styling ol-li3-li">商家同意將盡其所能在參與分行/分店展示由我們提供和要求的宣傳信息；</li>
                                    <li className="static-web-styling ol-li3-li">商家應賠償並使我們免於因路路香港提供任何服務予商家，而並非基於路路香港的收入所產生之任何費用、稅項或關稅。商家支付的此類費用、稅項和關稅不應視為其向路路香港應付服務款項的一部分，或予以扣除；及</li>
                                    <li className="static-web-styling ol-li3-li">除了因（a）被任何監管、政府或司法機關/機構要求披露，或（b）基於“需要知情”的原則下須披露給律師、審計員、保險公司或會計師而這些人士此後須履行保密義務的情況之外，路路香港和商家應將另一方視為保密性質的任何資料保持機密，並不得將其披露，或者導致或允許他人將其披露給任何個人或實體。</li>
                                </ol>
                            </li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>許可</h2>
                        <p>用戶授予我們無須繳付版權費用的、非獨享的、全球的、不可撤銷的、永久的權利和許可，在任何地方以任何形式使用、發佈、執行、展示、複製、傳播、推廣、下載、更改、重新格式化、創作衍生作品、轉移、銷售，或者以任何具商業性質或非商業性質的方式利用由用戶上傳或發佈在渠道上的任何材料，來實施載於<strong>協議</strong>所述的用途。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>知識產權</h2>
                        <p>渠道的所有內容和元素，包括不限於材料、用戶界面、版面、設計、聲音/音頻格式、資料庫、軟體和HTML 均是路路香港或用戶的知識產權（按情況而定），以及均受版權、商標、專利和其他適用的知識產權法律保護。用戶可下載及/或複制此類內容和元素作為其個人合法和非商業用途或<strong>協議</strong>明確允許的其他用途。用戶嚴格禁止使用任何上述之內容和元素作為除<strong>協議</strong>明確允許以外的任何用途，我們保留所有權利執行此等內容和元素中的所有知識產權。</p>
                        <p>為免生疑問，任何第三方（包括商家）聲稱同意使用<strong>協議</strong>提到的內容或其任何部分並不使用戶以任何方式免於<strong>協議</strong>所規定的限制和禁止。</p>
                        <p>用戶同意我們可以自由地使用、披露、採用及/或更改由用戶提供給路路香港任何與使用渠道和服務有關的資料，包括但不限於任何材料、想法、概念、提案、建議及評論，而毋須因此給予其任何報酬。用戶謹此放棄就我們使用、披露、採用及/或更改此等資料而向我們索取任何收費、特許權使用費、手續費及/或其他報酬的權利和索賠。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方網站/媒體平台/應用程式的連結</h2>
                        <p>渠道可能會提供非由我們擁有或管理的第三方網站/媒體平台/應用程式的連結。這些在渠道上提供的連結僅為了便利用戶及作為互聯網的導航工具，並絕不以任何方式視為我們對該等第三方網站/媒體平台/應用程式之內容的認可。除非另有註明，我們對此等第三方網站/媒體平台/應用程式沒有任何控制權，以及我們不對其內容、準確性和安全性負責。當用戶決定點擊渠道上允許用戶進入一個第三方網站/媒體平台/應用程式的任何連結時，他/她/它必須自行承擔所有風險。我們不會就用戶因使用該等第三方網站/媒體平台/應用程式而造成的損失或損害負責或承擔任何責任。此外，用戶在渠道上上傳或發佈的任何材料可能會被連結到渠道的其他網站/媒體平台/應用程式之使用者瀏覽，我們並不會對任何使用者或第三方通過第三方網站/媒體平台/應用程式而不當及/或非法地使用該等材料承擔任何責任。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>用戶與商家的往來</h2>
                        <p>用戶與商家在渠道上或通過渠道進行的任何交流往來或參與其促銷活動，包括支付及交付相關產品或服務，以及與此往來或促銷相關的任何其他條款、細則、保證或聲明，均僅在該用戶與商家之間進行。我們不對因任何該等往來或促銷，或者因商家發佈之任何材料的準確性而引致的任何損失或損害負責或承擔任何責任。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>責任</h2>
                        <p>我們或不會在任何時間都監控渠道但保留此權利。我們不會對非由我們發佈在渠道上或通過渠道發佈的任何材料承擔任何責任。用戶接受並同意對其提交至路路香港，或者其發佈在渠道上或通過渠道發佈的任何材料之形式、內容及準確性承擔全部責任。我們不保證任何材料將得到任何特定數目的用戶觀看或將被任何特定用戶觀看。</p>
                        <p>我們不對商家提供之產品的品質及合法性負責。我們不保證由任何商家發佈在渠道上之資料的準確性。如果商家與用戶之間發生任何爭議，他們雙方應彼此之間解決此等爭議，我們並不會對此等爭議負責或承擔任何責任。</p>
                        <p>就渠道的使用上，我們不應被視為任何用戶或商家的代理人。我們對使用渠道和服務的任何一方因任何原因造成直接或間接的損害不會承擔任何責任。</p>
                        <p><strong>儘管我們竭力向所有用戶提供優質服務，但不保證（a）渠道之運營毫無失誤，或者免受病毒或其他損害裝置之侵襲；（b）任何缺陷將得到糾正；（c）渠道之運營將不間斷、及時或安全。若用戶因使用渠道或服務導致其須要替換設備或數據，我們不會對此等費用及任何性質的損失或損害負責。渠道和其內容是按照“目前的模式”提供，在模式種類上沒有任何保證。在法律允許的最大限度內，我們拒絕承認一切保證，包括但不限於任何以下的保證（a）適銷性；（b）特定目的之適用性；（c）不侵犯第三方權利；（d）特色產品之可用性；及（e）渠道上的材料之準確性、可靠性、完整性或及時性。用戶應獨立評估並核證渠道材料之準確性、完整性及可靠性，並有必要時尋求專業意見。</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>自身風險</h2>
                        <p><strong>所有用戶須就使用渠道及通過渠道瀏覽任何其他的網站/媒體平台/應用程式而承擔自身風險</strong>。 所有用戶須對其在渠道上的通訊負責並承擔其在渠道上活動的後果。我們不代表或保證任何由用戶上傳或發佈的材料之準確性和可靠性，亦不代表我們贊同上傳者表達的觀點。所有用戶依賴其他用戶張貼的任何材料時應由其承擔自身風險。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>賠償</h2>
                        <p>在法律允許的最大限度內，所有用戶同意維護、保障及免除路路香港、其執行官、董事、僱員、代理人、合作商、代表、股東、服務人員、律師、前任人員、繼任人員和受讓人，因其使用渠道或通過渠道使用服務，或違反<strong>協議</strong>的任何條款，或與之有關而產生的任何索賠、要求、成本、責任、損失、損害和費用的責任（包括但不限於全額賠償基準上的法務費用）。如有任何此類索賠、訴訟、行動或法律程序，我們將及時通知相關用戶。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>免責聲明</h2>
                        <p><strong>在法律允許的最大限度內，路路香港、其執行官、董事、僱員、代理人、合作商、代表、股東、服務人員、律師、前任人員、繼任人員和受讓人絕不須要因（a）任何用戶使用或無法使用渠道和服務，包括但不限於上傳在渠道或透過渠道上傳之任何材料的任何不正確、錯誤、遺漏、中斷、刪除、傳輸延誤或丟失、因疏忽及因各方的分歧而無法使用；（b）任何用戶使用或無法使用任何商家提供的產品；和/或（c）任何商家的政策或行為，或與之有關而直接或間接引起或造成用戶的任何損失或損害賠償（包括但不限於直接、 間接、 偶然、 特殊、告誡性、懲罰性或後果性的損害賠償）承擔任何責任。</strong></p>
                        <p><strong>路路香港絕不是任何用戶或商家的代理。</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>系統可用性</h2>
                        <p>我們不能完全保證用戶可不間斷、連續或安全地通過渠道訪問任何服務。部分或全部渠道可能出於任何原因於某段時間內意外地無法訪問，這些原因包括但不限於系統故障及中斷、網絡攻擊及其他不可抗力的事故，我們對此原因概不負責（詳情請參閱第18條“<strong>不可抗力的事故</strong>”）。我們保留因不同原因，包括但不限於系統維修或升級，而將渠道的任何部分或全部離線而不作通知的權利。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>賬戶終止和材料移除</h2>
                        <p>我們保留權力隨時不作通知下以任何理由，包括但不限於違反<strong>協議</strong>、提供不準確的資料或不適當的材料及違反法例和規定，以暫停或終止其會員賬戶、限制或終止其訪問服務，或者封鎖其互聯網通信協定位址的方式，驅逐任何用戶並暫時或永久禁止其再次訪問渠道和服務。我們亦保留權利隨時不作通知下刪除任何具有辱駡性、非法性、分裂性或不適當的材料。</p>
                        <p>我們有全權及絕對酌情權決定用戶或材料有否違反了任何<strong>協議</strong>的條款及是否須要採取進一步的行動，包括但不限於對此用戶採取法律行動，而此決定應為最終的。如果我們沒有行使本條下的權利，其不應被視為對此權利的放棄。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>責任範圍</h2>
                        <p>在不影響上文及符合適用法律的基礎上，路路香港對任何用戶因使用渠道和服務或與之有關而提出的所有索償的整體責任上限為路路香港所收到導致此索償主張之服務的費用。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>適用法律及司法管轄權</h2>
                        <p>除非另有註明，此<strong>條款和細則</strong>須受香港的法例規管，並據此進行詮釋。</p>
                        <p>任何由此<strong>條款和細則</strong>或與之有關，或由於此<strong>條款和細則</strong>的違反、終止或無效而引起或與之有關的爭議、分歧或索賠，應根據截至目前生效的《聯合國國際貿易法委員會仲裁規則》並通過此條款其餘條文修正而仲裁解決：</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">指派機關應為仲裁中心；</li>
                            <li className="static-web-styling ol-li2-li">仲裁地點應設在香港的仲裁中心；</li>
                            <li className="static-web-styling ol-li2-li">需只有一名仲裁員；及</li>
                            <li className="static-web-styling ol-li2-li">在仲裁過程中使用的語言為英語。</li></ol><br/>
                        <p>如任何一方違反此<strong>條款和細則</strong>，另一方有權根據仲裁認定的普通法和衡平法採取補救措施。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>不可抗力的事故</h2>
                        <p>在不影響<strong>協議</strong>中任何限制責任的條款下，我們不對因超出我們控制範圍的情形而直接或間接導致履行義務的任何延遲或失敗而承擔任何責任。此不可抗力的事故包括但不限於互聯網中斷、電腦設備故障、電信設備故障或其他設備故障、停電、罷工、勞資糾紛、暴亂、叛亂、民間動亂、勞工或素材短缺、火災、水災、暴風、爆炸、天災、戰爭、政府行為、國內或國外法院或法庭命令、第三方的服務不履行及熱力、照明或空調供應的損失或波動。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>完整協定</h2>
                        <p><strong>協議</strong>構成路路香港與用戶就使用我們的渠道和服務所訂立的完整協定，並取代所有先前與該標的有關的一切書面或口頭的討論、協定、談判和通訊。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>可分割性</h2>
                        <p>本<strong>條款和細則</strong>的每一條款獨立於其他條款，其效力不受其他條款之無效性所影響。當<strong>條款和細則</strong>的任何條款或條款的任何部分已屬或變成違法、無效或不可執行時，<strong>條款和細則</strong>的其他條款或條款的剩餘部分的有效性和強制性不受影響，而為取代該等不合法、無效或不可執行的條款或條款的其中部分，須加入一項或更多相近而按照適用法例屬於合法、有效而且可予執行的條文，作為<strong>條款和細則</strong>的一部分。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方權利</h2>
                        <p>其他非本<strong>條款和細則</strong>的有關人士均沒有強制執行或享有本<strong>條款和細則</strong>中任何條款利益的權利。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>優先順序</h2>
                        <p>若此<strong>條款和細則</strong>、<strong>私隱政策</strong>、任何渠道之特定使用條款和任何服務合同之條款和細則，包括但不限於服務訂單和路路香港主辦任何特殊活動的任何具體使用條款，之間存在任何衝突，除非另有註明，其遞減優先順序如下：</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">任何服務合同之條款和細則；</li>
                            <li className="static-web-styling ol-li2-li"><strong>私隱政策</strong>；</li>
                            <li className="static-web-styling ol-li2-li">任何渠道之特定使用條款；及</li>
                            <li className="static-web-styling ol-li2-li">此<strong>條款和細則</strong>。</li></ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>主導語言</h2>
                        <p>如英文版本與其他語言的版本有任何抵觸或不相符之處，概以英文版本為準。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>聯絡方法</h2>
                        <p>如對這<strong>條款和細則</strong>有任何疑問，請透過以下途徑聯絡本公司的客戶服務代表：</p>
                        <address><strong>路路香港科技有限公司</strong><br/>
                        電郵地址：<a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>

        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/en/terms" lang="en" hrefLang="en" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/tc/terms" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/sc/terms" lang="zh-Hans" hrefLang="zh-Hans" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2025 路路香港科技有限公司</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default TermsWebTc;
